<template>
  <v-overlay v-model="activeEmailDialog" :persistent="true" :absolute="true" />
  <ProfileActiveEmailDialog
    v-if="showActiveEmailDialog"
    :show="showActiveEmailDialog"
    :email="profileStore.personalInformation.email"
  />
      <v-row
        class="d-flex bg-main-color full-width position-fixed h-13 justify-space-between align-center text-lg-body-1 z-index-999"
        id="header"
      >
      <div class="d-flex justify-space-between align-center full-width container">
        <ul class="d-flex justify-space-between w-60 align-center  font-weight-bold">
          <li
            class="d-flex mt-2 ml-16 pointer mr-lg-10"
            @click="navigateTo('/')"
          >
            <div>
              <HeaderMenu />
            </div>
            <img
              :width="mdAndDown ? '100' : '131'"
              src="/images/header/logo.svg"
              alt="log-image"
            />
          </li>
          <li class="isMobile">
            <NuxtLink
              :class="{ 'nuxt-link-activ': route.fullPath === '/' }"
              to="/"
            >
              الرئيسية
            </NuxtLink>
          </li>
          <li class="isMobile">
            <NuxtLink
              :class="{
                'nuxt-link-activ': route.fullPath === '/#how-to-start',
              }"
              :to="route.name === 'index' ? '#how-to-start' : '/'"
              @click="scrollTo('2050')"
            >
              كيف أبدأ</NuxtLink
            >
          </li>
          <li class="isMobile">
            <NuxtLink
              :class="{ 'nuxt-link-activ': route.fullPath === '/mentor/details' }"
              to="/mentor/details"
            >
              قائمة المرشدين
            </NuxtLink>
          </li>
          <li class="isMobile">
            <NuxtLink :to="authStore.coachUrl" target="blank"
              >انضم كمرشد</NuxtLink
            >
          </li>
          <li class="isMobile">
            <NuxtLink :to="route.name === 'index' ? 'contact-us' : '/'"
              >تواصل معنا</NuxtLink
            >
          </li>
        </ul>
        <div
          v-if="authStore.auth.isLoggedIn && !smAndDown"
          class="logout-section d-flex justify-center ml-5"
        >
          <div class="ml-4 mt-1 notifications">
            <img
              src="~/assets/images/icons/header/cart-icon.svg"
              @click="navigateTo('/checkout')"
              class="ml-4"
            />
            <v-badge
              dot
              color="red"
              v-if="notificationStore.numberOfNotifications !== 0"
            >
              <img
                src="~/assets/images/icons/header/notification-icon.svg"
                width="25"
                @click="handleShowNotification"
              />
            </v-badge>
            <img
              v-else
              src="~/assets/images/icons/header/notification-icon.svg"
              width="25"
              @click="handleShowNotification"
            />
          </div>

          <HeaderNotification
            :showNotifications="showNotification"
            @close="showNotification = false"
          />
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                size="x-large"
                class="rounded-xl btn-primary"
                @click="showMenuIcon = showMenuIcon === 'down' ? 'up' : 'down'"
                >{{ authStore.fullName }}
                <img
                  :src="`/images/header/arrow-${showMenuIcon}.svg`"
                  width="25"
                  class="pr-2"
                  alt="arrow-dropdown"
                />
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <NuxtLink to="/profile">
                    <div
                      class="d-flex justify-space-between px-2 py-2 rounded link-container"
                    >
                      <span class="text-secondary text-xs">حسابي</span>
                      <img src="~/assets/images/icons/header/user.svg" />
                    </div>
                  </NuxtLink>
                </v-list-item-title>
                <v-list-item-title>
                  <NuxtLink to="/wallet">
                    <div
                      class="d-flex justify-space-between px-2 py-2 rounded link-container"
                    >
                      <span class="text-secondary text-xs">المحفظة</span>
                      <img
                        src="~/assets/images/icons/header/wallet_.svg"
                        alt="wallet-icon"
                      />
                    </div>
                  </NuxtLink>
                </v-list-item-title>
                <v-list-item-title>
                  <NuxtLink to="/sessions">
                    <div
                      class="d-flex justify-space-between px-2 py-2 rounded link-container"
                    >
                      <span class="text-secondary text-xs">الجلسات</span>
                      <img
                        src="~/assets/images/icons/header/sessions.svg"
                        alt="sessions-icon"
                      />
                    </div>
                  </NuxtLink>
                </v-list-item-title>
                <v-list-item-title>
                  <NuxtLink to="/orders">
                    <div
                      class="d-flex justify-space-between px-2 py-2 rounded link-container"
                    >
                      <span class="text-secondary text-xs">الطلبات</span>
                      <img
                        src="~/assets/images/icons/header/orders.svg"
                        alt="sessions-icon"
                      />
                    </div>
                  </NuxtLink>
                </v-list-item-title>
                <v-list-item-title>
                  <div
                    class="d-flex justify-space-between px-2 py-2 rounded link-container pointer"
                    @click="authStore.signOut"
                  >
                    <span class="text-secondary text-xs">تسجيل الخروج</span>
                    <img src="~/assets/images/icons/header/sign-out.svg" />
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div
          v-else-if="authStore.auth.isLoggedIn && smAndDown"
          class="d-flex align-center"
        >
          <div class="ml-3 mt-1 notifications">
            <img
              src="~/assets/images/icons/header/cart-icon.svg"
              @click="navigateTo('/checkout')"
              width="22"

              class="ml-4"
            />
            <v-badge
              dot
              color="red"
              v-if="notificationStore.numberOfNotifications !== 0"
            >
              <img
                src="~/assets/images/icons/header/notification-icon.svg"
                width="25"
                @click="handleShowNotification"
              />
            </v-badge>
            <img
              v-else
              src="~/assets/images/icons/header/notification-icon.svg"
              width="25"
              @click="handleShowNotification"
            />
          </div>
          <HeaderNotification
            :showNotifications="showNotification"
            @close="showNotification = false"
          />
        </div>
        <div
          v-else
          class="d-flex ml-lg-10 ml-md-12 d-xl-flex d-lg-flex d-md-flex d-sm-none d-xs-none text-sm isMobile"
        >
            <v-btn @click="navigateTo('/mentor/details')" 
            size="x-large"
            class="rounded-xl ml-1 px-md-3 px-lg-8 text-sm font-weight-bold btn-primary px-16">ابدأ اﻵن</v-btn>
          <v-btn
            variant="outlined"
            size="x-large"
            color="black"
            class="rounded-xl px-md-3 px-lg-8 mr-1 font-weight-bold text-sm"
            @click="navigateTo('/auth/signin')"
          >
          دخول / تسجيل</v-btn
          >
        </div>
      </div>
      </v-row>

</template>
<script setup>
import { useDisplay } from 'vuetify';
import { useAuthStore } from '~~/stores/AuthStore';
import { useNotificationStore } from '~~/stores/NotificationStore';
import { useProfileStore } from '~~/stores/ProfileStore';

const authStore = useAuthStore();
const { smAndDown, mdAndDown } = useDisplay();
const notificationStore = useNotificationStore();
const profileStore = useProfileStore();
const route = useRoute();

watch(
  () => authStore.auth.isLoggedIn,
  (newValue) => {
    if (newValue) {
      notificationStore.fetch();
      setTimeout(() => {
        profileStore.getPersonalInformation();
      }, 600);
    }
  }
);
const activeEmailDialog = ref(false);
const showActiveEmailDialog = ref(false);

const showMenuIcon = ref('down');

const handleScroll = () => {
  const header = document.querySelector('#header');
  if (header) {
    if (window.pageYOffset > 0) {
      header.classList.add('scroll');
    } else {
      header.classList.remove('scroll');
    }
  }
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  setTimeout(async () => {
    if (authStore.auth.isLoggedIn) {
      notificationStore.fetch();
      await profileStore.getPersonalInformation();
      activeEmailDialog.value =
        !profileStore.personalInformation.isEmailVerified;
      showActiveEmailDialog.value =
        !profileStore.personalInformation.isEmailVerified;
    }
  }, 500);
});

const showNotification = ref(false);

async function handleShowNotification() {
  if (showNotification.value === false) {
    notificationStore.fetch();
    if(notificationStore.numberOfNotifications !== 0) {
      await notificationStore.markAllAsRead();
    }
  }
  showNotification.value = !showNotification.value;
}

function scrollTo(value) {
  if (route.name === 'index') {
    setTimeout(() => {
      window.scrollTo({
        top: value,
        behavior: 'smooth',
      });
    }, 100);
  }
}
</script>
<style lang="scss" scoped>
.notifications {
  cursor: pointer;
  white-space: nowrap;
}

@media (max-width: 1100px) {
  .isMobile{
  display: none !important;
  }
}
</style>
