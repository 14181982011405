<template>
     <v-dialog
      v-model="showDialog"
      persistent
      width="85%"
    >
        <v-card>
          <div v-if="stage == 1" class="d-flex justify-center align-center flex-column pa-7 text-center rounded-xl">
            <img src="~/assets/images/notifications/notification.png" alt="" :class="smAndDown ? 'w-70': 'w-50'">

            <h1 class="my-4 text-md text-black-color">تفعيل اﻹشعارات</h1>
            <p v-if="message == ''" class="text-center mb-10 text-black-color">للحصول على أفضل تجربة ولتتمكن من استقبال الإشعارات، يُرجى إضافة هذا الموقع إلى الشاشة الرئيسية لجهازك</p>
            <p v-else class="text-center mb-10 text-black-color">{{ message }}</p>
            <v-btn v-if="message == ''" @click="stage = 2" block size="large" class="rounded-lg">
                اضغط هنا لتوضيح التعليمات
            </v-btn>
          </div>
          <div v-if="stage == 2" data-aos="fade-right" data-aos-duration="500" class="d-flex justify-center align-center flex-column py-10 rounded-xl">
            <img src="~/assets/images/notifications/step_2.png" alt="step_2" :class="smAndDown ? 'w-50' : 'w-30'">
            <div class="mt-3">
              <v-btn @click="stage = 3" :size="smAndDown ? 'large' : 'x-large'"  class="mx-1 px-16">
                التالي
              </v-btn>
              <v-btn @click="stage = 4" variant="outlined" :size="smAndDown ? 'large' : 'x-large'" class="mx-1">
                تخطي 
                <img src="~/assets/images/coaches/arrows.svg" alt="" class="w-30 mr-2">
              </v-btn>
            </div>
          </div>
          <div v-if="stage == 3" data-aos="fade-right" data-aos-duration="500" class="d-flex justify-center align-center flex-column py-10 rounded-xl">
            <img src="~/assets/images/notifications/step_3.png" alt="step_2" :class="smAndDown ? 'w-50' : 'w-30'">
            <div class="mt-3">
              <v-btn @click="stage = 4" :size="smAndDown ? 'large' : 'x-large'"  class="mx-1 px-16">
                التالي
              </v-btn>
              <v-btn @click="stage = 4" variant="outlined" :size="smAndDown ? 'large' : 'x-large'" class="mx-1">
                تخطي 
                <img src="~/assets/images/coaches/arrows.svg" alt="" class="w-30 mr-2">
              </v-btn>
            </div>
          </div>
          <div v-if="stage == 4" data-aos="fade-right" data-aos-duration="500" class="d-flex justify-center align-center flex-column py-10 rounded-xl">
            <img src="~/assets/images/notifications/step_4.png" alt="step_2" :class="smAndDown ? 'w-50' : 'w-30'">
            <div class="mt-3">
              <v-btn @click="stage = 1" :size="smAndDown ? 'large' : 'x-large'"  class="mx-1 px-16">
                حسنا, فهمت
              </v-btn>
            </div>
          </div>
        </v-card> 
    </v-dialog>
</template>
<script setup>
import { useDisplay } from 'vuetify';
const { smAndDown } = useDisplay();

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: '',
  },
});

const showDialog = ref(props.show);
const stage = ref(1);

</script>