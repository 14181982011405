<template>
  <SharedResponseAlert :response="response" />
    <v-dialog width="500" v-model="isActive">
      <v-card class="px-1">
        <slot>
          <v-card-text class="d-flex flex-column align-center">
            <h1 class="text-lg bold-600 black-dark-color mb-8">تفعيل البريد الإلكتروني</h1>
            <img src="~/assets/images/shared/active-email-icon.svg" alt="Activate email icon" />
            <p class="mt-3">الرجاء تفعيل البريد الإلكتروني لاستكمال عملياتك</p>
            <v-btn
              variant="text"
              class="bg-primary mt-8"
              @click="handleActiveEmail"
            >
            إعادة إرسال التفعيل
            </v-btn>
          </v-card-text>
        </slot>
      </v-card>
    </v-dialog>
  </template>
  <script setup>
import { useAuthStore } from '~~/stores/AuthStore';

const authStore = useAuthStore();

  const props = defineProps({
    show: {
      type: Boolean,
      default: false,
    },
    email:{
      type: String,
      default: "", 
    }
  });

  const isActive = ref(props.show);
  
  const response = computed(() => {
  return authStore.responseData;
  });

  watch(
    () => props.show,
    (newValue) => {
      isActive.value = newValue;
    },
    { deep: true }
  );
  
  async function handleActiveEmail(){
    await authStore.resendActivationEmail(props.email);
  }
  </script>