import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { useAuthStore } from '~~/stores/AuthStore';

export const useNotificationStore = defineStore('notifications', {
  state: () => ({
    notifications: null,
    numberOfNotifications: 0,
    notificationTotal: 0,
    loading: false,
    serverErrors: {},
  }),
  actions: {
    async fetch() {
      const authStore = useAuthStore();
      this.loading = true;

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/notifications`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
        }
      );

      this.loading = false;
      if (!error.value) {
        this.notifications = response.value ? response.value.data.notifications.data : [];
        this.numberOfNotifications = response.value ? response.value.data.numberOfNotifications : 0;
        this.notificationTotal = response.value ? response.value.data.notifications.total : 0;
      } else {
        this.serverErrors = error.value;
      }
    },
    async markAsRead(notificationId) {
      const authStore = useAuthStore();
      const formData = new FormData();
      if (notificationId.length > 0) {
        for (let i = 0; i < notificationId.length; i++) {
          formData.append('ids[]', notificationId);
        }
      }
      this.loading = true;

      const { error } = await useFetch(
        `${
          useRuntimeConfig().public.baseUrl
        }/notifications?ids[]=${notificationId}`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: {
            ids: notificationId,
          },
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetch();
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
      }
    },
    async markAllAsRead() {
      const authStore = useAuthStore();
      this.loading = true;
      const { error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/notifications?read_all=true`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.fetch();
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
      }
    },
  },
});
