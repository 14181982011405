<template>
  <v-card class="pa-5" max-width="500">
<NotificationPostponeSessionRequest v-if="notificationData.type === 'postpone_session'"/>
  </v-card>
</template>
<script setup>
import { useAuthStore } from '~~/stores/AuthStore';
const authStore = useAuthStore();

const notificationData = computed(() => {
  return authStore.pushNotificationData;
});

</script>
