<template>
  <div class="d-flex mb-2">
    <img :src="`/images/notification/${notificationData.icon}.svg`" />
    <h2 class="text-sm black-dark-color mr-2">{{ notificationData.title }}</h2>
  </div>
  <p
    class="text-xs black-dark-color mb-3"
    v-html="notificationData.message"
  ></p>
    <div>
      <v-btn
        variant="text"
        class="bg-primary ml-2"
        size="small"
        @click="
          handlePostponementAppointment(
            'accept',
            notificationData.button_action_id
          )
        "
      >
        قبول
      </v-btn>
      <v-btn
        variant="text"
        class="bg-red"
        size="small"
        @click="
          handlePostponementAppointment(
            'reject',
            notificationData.button_action_id
          )
        "
      >
        رفض
      </v-btn>
    </div>
</template>
<script setup>
import { useAuthStore } from '~~/stores/AuthStore';
import { usePostponeSessionStore } from '~~/stores/PostponeSessionStore';

const authStore = useAuthStore();
const postponeSessionStore = usePostponeSessionStore();

const notificationData = computed(() => {
  return authStore.pushNotificationData;
});

async function handlePostponementAppointment(status, id) {
  await postponeSessionStore
    .update({ id: id, data: status })
    .then((success) => {
        authStore.pushNotificationResponse = success;
        authStore.pushNotificationData = null;
    });
}
</script>

<style scoped>
.bg-red {
  background-color: #f44f47;
}
</style>