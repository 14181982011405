<template>
  <v-snackbar
    v-model="postponementAppointment.showDialog"
    :timeout="4000"
    :color="postponementAppointment.isSendBefore ? 'error' : 'success'"
  >
    <div class="text-center">
      {{ postponementAppointment.message }}
    </div>
  </v-snackbar>
  <div
    v-if="showNotification"
    class="card-notification"
    v-click-outside="closeDiv"
  >
    <h1 class="text-lg black-dark-color notification-title">الإشعارات</h1>
    <div cols="12" v-if="notificationStore.loading">
            <looping-rhombuses-spinner
              class="mx-auto d-flex justify-center align-center"
              :animation-duration="3000"
              :rhombus-size="20"
              color="#8ae5e0"
            />
          </div>
    <div
      :class="showAllNotification === true ? 'notification-content' : ''"
      v-else-if="notificationStore.notificationTotal > 0"
    >
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="card-content py-3 px-5 d-flex align-start"
        :class="notification.isRead == false ? 'bg-unread' : ''"
      >
        <img
          :src="`/images/notification/${notification.icon}.svg`"
          :alt="notification.icon"
        />
        <div class="pr-3 w-90">
          <h2 class="text-sm black-dark-color">
            {{ notification.title }}
            <span class="text-xs text-grey mr-1 font-weight-regular">{{
              notification.createdAt
            }}</span>
          </h2>
          <p
            class="text-xs black-dark-color my-2"
            v-html="notification.message"
          ></p>
          <div
            v-if="
              notification.type == 'postpone_session' ||
              notification.type === 'direct-session-request' ||
              notification.type === 'scheduled-session-time'
            "
            class="d-flex"
          >
            <div v-if="notification.status == 'pending'">
              <div v-if="notification.type == 'postpone_session'">
                <v-btn
                  variant="text"
                  class="bg-primary ml-2"
                  size="small"
                  @click="
                    handlePostponementAppointment(
                      'accept',
                      notification.buttonActionId,
                      notification.id
                    )
                  "
                >
                  قبول
                </v-btn>
                <v-btn
                  variant="text"
                  class="bg-red"
                  size="small"
                  @click="
                    handlePostponementAppointment(
                      'reject',
                      notification.buttonActionId,
                      notification.id
                    )
                  "
                >
                  رفض
                </v-btn>
              </div>
              <div v-if="notification.type == 'direct_session_request'">
                <v-btn
                  variant="text"
                  class="bg-primary ml-2"
                  size="small"
                  @click="sendRespond('accepted')"
                >
                  قبول
                </v-btn>
                <v-btn
                  variant="text"
                  class="bg-red"
                  size="small"
                  @click="sendRespond('rejected')"
                >
                  رفض
                </v-btn>
              </div>
            </div>
            <span
              v-else-if="notification.status === 'accepted'"
              class="text-success"
              >تم القبول</span
            >
            <span
              v-else-if="
                notification.status === 'rejected' ||
                notification.status === 'canceled'
              "
              class="text-danger"
              >{{
                notification.status === "rejected" ? "تم الرفض" : "تم الإلغاء"
              }}</span
            >

            <div v-else-if="notification.status === 'in_progress'">
              <v-btn
                variant="text"
                class="bg-primary ml-2"
                size="small"
                @click="startSession(notification.buttonActionId)"
              >
                الدخول إلى الجلسة
              </v-btn>
            </div>
            <span
              v-else-if="notification.status === 'finished'"
              class="text-danger"
              >انتهت الجلسة</span
            >
            <v-btn
              to="/checkout"
              @click="emit('close')"
              v-if="
                notification.type === 'direct-session-request' &&
                notification.status === 'accepted'
              "
              size="small"
              class="mr-2"
              >إتمام الدفع</v-btn
            >
          </div>

          <div v-if="notification.type == 'cancel_session'">
            <v-btn
              variant="text"
              class="bg-primary ml-2"
              size="small"
              @click="showSessionDetails(notification.buttonActionId)"
            >
              تفاصيل الجلسة
            </v-btn>
          </div>
        </div>
        <div
          v-if="notification.isRead == false"
          class="dot-unread align-self-center rounded-circle mr-3"
        ></div>
      </div>
    </div>
    <div
      v-else
      class="card-content px-5 py-8 d-flex justify-center align-center"
    >
      <p class="text-sm text-grey">لا توجد لديك إشعارات</p>
    </div>
    <div class="action-buttons">
      <v-btn
        variant="text"
        class="bg-primary show-all-notification-btn"
        @click="
          notificationStore.notificationTotal >= 4
            ? (showAllNotification = true)
            : (showAllNotification = false)
        "
      >
        عرض جميع الإشعارات
      </v-btn>
      <div class="mark-all-as-read" @click="markAllNotificationsAsRead">
        <span class="text-xs text-primary pointer font-weight-bold ml-2"
          >تمييز الكل كـ مقروءة</span
        >
        <img src="~/assets/images/icons/header/read-tick.svg" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useNotificationStore } from '~~/stores/NotificationStore';
import { usePostponeSessionStore } from '~~/stores/PostponeSessionStore';
import { LoopingRhombusesSpinner } from 'epic-spinners';
import { useAuthStore } from '~~/stores/AuthStore';

const emit = defineEmits(['close']);

const props = defineProps({
  showNotifications: {
    type: Boolean,
    default: false,
  },
});

const notificationStore = useNotificationStore();
const authStore = useAuthStore();
const postponeSessionStore = usePostponeSessionStore();
notificationStore.fetch();
const showNotification = ref(false);
const show = ref(false);

// Handle postponement appointment actions
const postponementAppointment = ref({
  message: '',
  showDialog: false,
  isSendBefore: false,
});

async function handlePostponementAppointment(status, id, notificationId) {
  notificationStore.markAsRead([notificationId]);
  await postponeSessionStore
    .update({ id: id, data: status })
    .then((success) => {
      notificationStore.fetch();
      postponementAppointment.value.message = success;
      postponementAppointment.value.showDialog = true;
      postponementAppointment.value.isSendBefore = false;
    });
}

// Handle show all notification and mark all as read
const showAllNotification = ref(false);

const notifications = computed(() => {
  if (notificationStore.notifications != null) {
    return showAllNotification.value == false
      ? notificationStore.notifications.slice(0, 4)
      : notificationStore.notifications;
  }
});

function showSessionDetails(sessionId) {
  navigateTo(`/sessions/session-details/${sessionId}`);
  showNotification.value = false;
}
async function markAllNotificationsAsRead() {
  await notificationStore.markAllAsRead();
}

function startSession(sessionId) {
  authStore.currentSessionId = sessionId;
  navigateTo('/room');
}

watch(
  () => props.showNotifications,
  (newValue) => {
    showNotification.value = newValue;

    if (newValue) {
      notificationStore.fetch();
    }
  }
);

function closeDiv() {
  show.value = true;
  showNotification.value = false;
}
</script>

<style lang="scss" scoped>
.card-notification {
  width: 550px;
  border-radius: 20px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: absolute;
  top: 80px;
  left: 200px;
  z-index: 9999;

  .notification-title {
    padding: 25px;
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
  }
  .notification-content {
    overflow-y: scroll;
    height: 60vh;
  }
  .card-content {
    border: solid 1px #e3e3e3;
  }
  .bg-red {
    background-color: #f44f47;
  }
  .bg-unread {
    background-color: #dfeeed;
  }
  .dot-unread {
    background-color: #61aaa7;
    padding: 6px;
  }
}

@media (max-width: 800px) {
  .card-notification {
    width: 500px;
    left: 100px;
  }
}
@media (max-width: 610px) {
  .card-notification {
    width: 400px;
    left: 100px;
  }
}
@media (max-width: 610px) {
  .card-notification {
    width: 400px;
    left: 60px;
  }
}
@media (max-width: 450px) {
  .card-notification {
    width: 400px;
    left: 10px;
    .notification-title {
      padding: 10px;
    }
  }
}
@media (max-width: 420px) {
  .card-notification {
    width: 350px;
    left: 5px;
  }
  .action-buttons {
    display: block !important;
    padding: 10px !important;
    margin: 5px;
    .mark-all-as-read {
      text-align: center;
    }
  }
}
@media (max-width: 360px) {
  .card-notification {
    width: 300px;
    left: 5px;
  }
  .action-buttons {
    padding: 0px;
    .show-all-notification-btn {
      display: block !important;
      margin: 10px;
    }
  }
}
@media (max-width: 310px) {
  .card-notification {
    width: 250px;
    left: 5px;
  }
}
</style>
